import Headroom from "headroom.js";
import Swiper from "swiper";
import SmoothScroll from "smooth-scroll";
import fslightbox from "fslightbox";

const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
    updateURL: false,
    popstate: false,
    topOnEmptyHash: true
});

const dynamicCarousels = document.querySelectorAll("[data-carousel]");

dynamicCarousels.forEach(function(carousel, index) {
    let title = carousel.getAttribute("data-carousel");
    let count = carousel.getAttribute("data-carousel-count");
    window["dynamicCarousel" + index] = new Swiper(`.${title}`, {
        watchOverflow: true,
        speed: 300,
        spaceBetween: 12,
        slidesPerView: 2,
        navigation: {
            nextEl: carousel.parentElement.querySelector(".carousel-next"),
            prevEl: carousel.parentElement.querySelector(".carousel-prev")
        },
        breakpoints: {
            576: {
                slidesPerView: 3
            },
            768: {
                slidesPerView: 4
            },
            1200: {
                slidesPerView: count ? count : 6,
                spaceBetween: 15
            }
        }
    });
});

var othersServices = new Swiper(".other-services-swiper", {
    // watchOverflow: true,
    speed: 700,
    spaceBetween: 12,
    slidesPerView: 1,
    navigation: {
        nextEl: ".swiper-button-next-services",
        prevEl: ".swiper-button-prev-services"
    },
    pagination: {
        el: ".swiper-pagination-services",
        type: "bullets",
        clickable: true
    },
    // autoplay: {
    //     delay: 3000
    // },
    breakpoints: {
        576: {
            slidesPerView: 3
        },
        768: {
            slidesPerView: 4
        },
        1200: {
            slidesPerView: 5
        }
    }
});

var groundWork = new Swiper(".ground-work-swiper", {
    watchOverflow: true,
    speed: 700,
    spaceBetween: 12,
    slidesPerView: 1,
    navigation: {
        nextEl: ".swiper-button-next-ground",
        prevEl: ".swiper-button-prev-ground"
    },
    breakpoints: {
        576: {
            slidesPerView: 2
        },
        992: {
            slidesPerView: 3
        },
        1200: {
            slidesPerView: 4
        }
    }
});

var groundWorkOthers = new Swiper(".ground-work-others-swiper", {
    watchOverflow: true,
    speed: 700,
    spaceBetween: 12,
    slidesPerView: 1,
    navigation: {
        nextEl: ".swiper-button-next-ground-others",
        prevEl: ".swiper-button-prev-ground-others"
    },
    breakpoints: {
        576: {
            slidesPerView: 2
        },
        992: {
            slidesPerView: 3
        },
        1200: {
            slidesPerView: 4
        }
    }
});

var introCarousel = new Swiper(".intro__carousel", {
    watchOverflow: true,
    speed: 700,
    slidesPerView: 1,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
    },
    pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true
    },
    autoplay: {
        delay: 3000
    }
});
let introCarouselEl = document.querySelector(".intro__carousel");
if (introCarouselEl) {
    introCarouselEl.onmouseover = function(event) {
        introCarousel.autoplay.stop();
    };
    introCarouselEl.onmouseout = function(event) {
        introCarousel.autoplay.start();
    };
}

var theySaidCarousel = new Swiper(".they-said__carousel", {
    speed: 300,
    loop: false,
    slidesPerView: 1,
    autoHeight: true,
    effect: "fade",
    fadeEffect: {
        crossFade: true
    },
    navigation: {
        nextEl: ".they-said-next",
        prevEl: ".they-said-prev"
    }
});

//BURGER MENU
const header = document.getElementById("header-wrapper");
const burgerMenu = document.getElementById("burger-menu");
const burgerMenuOpen = document.getElementById("burger-menu-open");
const burgerMenuClose = document.getElementById("burger-menu-close");
const burgerMenuOverlay = document.getElementById("burger-overlay");
const headroom = new Headroom(header, {
    offset: document.getElementById("header-wrapper").offsetHeight,
    tolerance: {
        up: 10,
        down: 0
    }
});
headroom.init();

burgerMenuOpen.addEventListener("click", () => {
    burgerMenu.classList.add("open");
    burgerMenuOverlay.classList.add("open");
});

burgerMenuClose.addEventListener("click", () => {
    burgerMenu.classList.remove("open");
    burgerMenuOverlay.classList.remove("open");
});

burgerMenuOverlay.addEventListener("click", () => {
    burgerMenu.classList.remove("open");
    burgerMenuOverlay.classList.remove("open");
});

//Scroll up arrow
const scrollUp = document.getElementById("up");
if (scrollUp) {
    scrollUp.addEventListener("click", function() {
        scroll.animateScroll(0);
    });
}

function scrollUpToggle() {
    if (window.pageYOffset > 100) {
        if (window.oldScroll > window.scrollY) {
            document.getElementById("up").classList.add("active");
        } else {
            document.getElementById("up").classList.remove("active");
        }
    } else {
        document.getElementById("up").classList.remove("active");
    }
    window.oldScroll = window.scrollY;
}

window.onload = function() {
    scrollUpToggle();
};

window.onscroll = function() {
    scrollUpToggle();
};

//Contact form show

const contactBtnToggler = document.getElementById("js-writeUs");
const contactSection = document.querySelector(".contact-form");
const contactOverlay = document.querySelector(".contact-form__overlay");
const contactBtnClose = document.querySelector(".contact-form__close");

if (contactBtnToggler) {
    contactBtnToggler.addEventListener("click", () => {
        contactSection.classList.toggle("active");
        contactOverlay.classList.toggle("active");
    });

    contactBtnClose.addEventListener("click", () => {
        contactSection.classList.remove("active");
        contactOverlay.classList.remove("active");
    });

    contactOverlay.addEventListener("click", () => {
        contactSection.classList.remove("active");
        contactOverlay.classList.remove("active");
    });
}

const dropdowns = document.querySelectorAll(".menu-dropdown");

dropdowns.forEach(function(dropdown) {
    dropdown.addEventListener("click", e => {
        e.preventDefault();
        dropdown.classList.toggle("show");
    });
});
const dropdownItems = document.querySelectorAll(".menu-dropdown-item");

dropdownItems.forEach(function(dropdownItem) {
    dropdownItem.addEventListener("click", e => {
        e.stopPropagation();
    });
});

//remove slider lock class on load
let swiperButton = document.querySelectorAll(
    ".other-services .swiper-button-prev"
);

[].forEach.call(swiperButton, function(el) {
    el.classList.remove("swiper-button-lock");
});

let swiperButtonNext = document.querySelectorAll(
    ".other-services .swiper-button-next"
);

[].forEach.call(swiperButtonNext, function(el) {
    el.classList.remove("swiper-button-lock", "swiper-button-disabled");
});

$(".tooltip-btn").on("click", function() {
    $(this)
        .closest(".tooltip-tab")
        .toggleClass("active-tooltip");
});
